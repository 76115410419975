import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from '@web-for-marketing/react-ui';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { LanguageCode } from '@models/languageCode';
import { translatedOptions } from '@helpers/selectOptions/translatedOptions';
import { logError } from '@helpers/errors';

const lazyLoadedSelectOptionsDynamicImports = {
    country: import(/* webpackChunkName: "select-options" */ '@helpers/selectOptions/countryOptions'),
    province: import(/* webpackChunkName: "select-options" */ '@helpers/selectOptions/canadianProvinceOptions'),
    state: import(/* webpackChunkName: "select-options" */ '@helpers/selectOptions/americanStateOptions'),
    australianStates: import(/* webpackChunkName: "select-options" */ '@helpers/selectOptions/australianStateOptions'),
    europeanCountries: import(/* webpackChunkName: "select-options" */ '@helpers/selectOptions/europeanCountryOptions'),
    title: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['titles'][language]}`
        ),
    industry: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['industry'][language]}`
        ),
    areaOfInterest2: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['interest2'][language]}`
        ),
    adminService: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['adminService'][language]}`
        ),
    commerce: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['commerce'][language]}`
        ),
    construction: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['construction'][language]}`
        ),
    education: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['education'][language]}`
        ),
    entertainment: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['entertainment'][language]}`
        ),
    finance: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['finance'][language]}`
        ),
    government: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['government'][language]}`
        ),
    healthcare: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['healthcare'][language]}`
        ),
    hospitality: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['hospitality'][language]}`
        ),
    transportation: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['transportation'][language]}`
        ),
    utilities: (language: LanguageCode) =>
        import(
            /* webpackChunkName: "select-options" */ `@helpers/selectOptions/${translatedOptions['utilities'][language]}`
        ),
};

export type LazyLoadedSelectOptionsTypes = keyof typeof lazyLoadedSelectOptionsDynamicImports;

export function useLazyLoadedSelectOptions(
    lazyLoadedSelectOptionsTypes: LazyLoadedSelectOptionsTypes[]
): SelectOption[][] {
    const [selectOptions, setSelectOptions] = useState<SelectOption[][]>([[], []]);
    const [selectedLanguage] = useAtom(selectedLanguageAtom);

    const dynamicImports = useMemo(() => {
        return lazyLoadedSelectOptionsTypes.map((optionType) => {
            const dynamicImport = lazyLoadedSelectOptionsDynamicImports[optionType];
            if (typeof dynamicImport === 'function') {
                return dynamicImport(selectedLanguage);
            }
            return dynamicImport;
        });
    }, [lazyLoadedSelectOptionsTypes, selectedLanguage]);

    useEffect(() => {
        Promise.all(dynamicImports)
            .then((loaders) => {
                setSelectOptions(loaders.map((loader) => loader.default));
            })
            .catch((error) => logError(error, 'get/set dynamic imports'));
    }, [dynamicImports]);

    return selectOptions;
}
